<template>
  <v-main>
    <PagePopUp
      v-model="dialog"
      :page="openpage"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="(page, i) in news"
          :key="i"
          class="mb-2"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card class="fill-height">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="headline text-left"
                  style="white-space: normal;"
                >
                  {{
                    page.title
                  }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-left">
                  {{
                    page.created | dateformat
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text
              class="text-left"
              v-html="page.description"
            />

            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                @click="openDialog(page)"
              >
                Читать
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import news from "@/api/news";
import PagePopUp from "@/components/PagePopUp";

export default {
  name: "NewsList",
  components: {
    PagePopUp,
  },
  data: () => ({
    news: [],
    loading: true,
    dialog: false,
    openpage: {},
  }),
  created() {
    news.getAllNews().then((response) => {
      this.news = response.data;
      this.loading = false;
    });
  },
  methods: {
    openDialog(page) {
      this.dialog = true;
      this.openpage = page;
    },
  },
};
</script>
