import api from '@/api';

const path = '/news/';

export default {
  getAllNews() {
    return new Promise((resolve, reject) => {
      api
        .get(path)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getNews(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`${path}${id}/`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
}
